import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import { GetCategoryDetails } from "../../../../services";
import Edit from "./edit";
import swal from "sweetalert";
export default class MainCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      slug: "",
      image: "",
      error: "",
      getList: [],
    };
  }
  sanitizeInput(input) {
    return input.replace(/[^a-zA-Z0-9\s,-]/g, "");
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value, error: "" });
  }
  // handleChange(e) {
  //   const name = e.target.name;
  //   // const value = this.sanitizeInput(e.target.value); // Sanitize the input
  //   const value = e.target.type === 'file' ? e.target.files[0] : this.sanitizeInput(e.target.value);
  //   this.setState({ [name]: value, error: "" });
  // }
  handleKeyPress = (e) => {
    const regex = /^[a-zA-Z0-9\s,-]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
      this.setState({
        error: "Special characters  are not allowed in this field.",
      });
    }
  };
  // handlePaste(e) {
  //   // Prevent pasting numbers and special characters into the "Name" field
  //   const clipboardData = e.clipboardData || window.clipboardData;
  //   const pastedText = clipboardData.getData("text/plain");
  //   const sanitizedText = this.sanitizeInput(pastedText);
  //   document.execCommand("insertText", false, sanitizedText);
  //   e.preventDefault();
  //   this.setState({
  //       error: "Special characters and numbers are not allowed.",
  //     });
  // }
  handlePaste(e) {
    e.preventDefault();
    this.setState({
      error: "Pasting is not allowed in this field.",
    });
  }
  handleBack() {
    this.props.history.goBack();
  }
  async getCategory() {
    let list = await GetCategoryDetails.getCategoryList();
    this.setState({ getList: list.data });
  }
  async componentDidMount() {
    this.getCategory();
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  async handlDeleteById(id) {
    swal({
      title: "Are you sure?",
      text: "You want to delete Category from the List",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        let value = await GetCategoryDetails.getDeleteCategoryById(id);
        if (value) {
          this.getCategory();
        }
      }
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const paylod = new FormData(event.target);
    // const { name, slug } = this.state;
    // let data = { name: name, slug: slug };
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    swal({
      title: "Are you sure?",
      text: "You want to Add New Category",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        let list = await GetCategoryDetails.createCategoryList(paylod, config);
        if (list) {
          this.getCategory();
        }
      }
    });
  };
  render() {
    let self = this.state.getList;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">Add Category</h2>
          </div>
          <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
            <Button variant="contained" onClick={(e) => this.handleBack()}>
              <i class="fas fa-arrow-left" /> Back
            </Button>
          </div>
        </div>
        {/* <ol className="breadcrumb mb-30">
                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                    <li className="breadcrumb-item active">Category</li>
                </ol> */}
        <div className="row">
          <div className="col-lg-4 col-md-5">
            <div className="card card-static-2 mb-30">
              {/* <div className="card-title-2">
                                <h4>Add Main Category</h4>
                            </div> */}
              <div className="card-body-table">
                <div className="news-content-right pd-20">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label className="form-label">Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category name"
                        name="name"
                        value={this.state.name}
                        onChange={(e) => this.handleChange(e)}
                        onKeyPress={this.handleKeyPress}
                        onPaste={(e) => this.handlePaste(e)}
                      />
                      {this.state.error && (
                        <p className="text-danger">*{this.state.error}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">slug*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category slug"
                        name="slug"
                        value={this.state.name
                          .toLowerCase()
                          .replace(/ |,/g, "-")}
                        onChange={(e) => this.handleChange(e)}
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Image*</label>
                      <input
                        type="file"
                        className="form-control"
                        placeholder="Category Image"
                        name="image"
                        value={this.state.image}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className="form-group mb-0">
                      <button className="btn btn-primary" type="submit">
                        Add New
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-7">
            <div className="all-cate-tags">
              <div className="row justify-content-between">
                <div className="col-lg-12 col-md-12">
                  <div className="card card-static-2 mb-30">
                    <div className="card-title-2">
                      <h4>All Main Categories</h4>
                    </div>
                    <div className="card-body-table">
                      <div className="table-responsive">
                        <table className="table ucp-table table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: 60 }}>
                                {/* <input type="checkbox" className="check-all" /> */}
                                Sl No.
                              </th>
                              <th scope="col">Name</th>
                              <th scope="col">Slug</th>
                              <th scope="col">Date</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {self.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <input
                                    type="checkbox"
                                    className="check-item"
                                    name="ids[]"
                                    defaultValue={5}
                                  /> */}
                                  {index + 1}
                                </td>
                                <td>{row.name}</td>
                                <td>{row.slug}</td>
                                <td>
                                  <span className="delivery-time">
                                    {this.formatDate(row.createdAt)}
                                  </span>
                                </td>
                                <td className="action-btns">
                                  <Edit state={row} />
                                  {/* <Typography className="delete-btn" onClick={(e) => this.handlDeleteById(row.id)} ><i className="fas fa-trash-alt" /></Typography> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
