import React, { Component } from "react";
import { GetDeliverySlots } from "../../../../services";
import { Typography, Button } from "@material-ui/core";
import Edit from "../edit";
import swal from "sweetalert";
export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getList: [],
    };
  }
  handleBack() {
    this.props.history.goBack();
  }
  async componentDidMount() {
    this.GetDeliverySlots();
  }
  async GetDeliverySlots() {
    let list = await GetDeliverySlots.getDeliverySlots();
    console.log(list)
    this.setState({ getList: list.data });
  }
  
  render() {
    let self = this.state.getList;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">Delivery Slots</h2>
          </div>
          <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
            <Button variant="contained" onClick={(e) => this.handleBack()}>
              <i class="fas fa-arrow-left" /> Back
            </Button>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-12">
            <a href="/admin/deliveryslots/create" className="add-btn hover-btn">
              Add New
            </a>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        {/* <th style={{ width: 60 }}>
                          <input type="checkbox" className="check-all" />
                        </th> */}
                        <th style={{ width: 60 }}>Sl No.</th>
                        <th>Slot Name</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {self.map((row, index) => (
                        <tr key={index}>
                          {/* <td>
                            <input
                              type="checkbox"
                              className="check-item"
                              name="ids[]"
                              defaultValue={7}
                            />
                          </td> */}
                          <td>{++index}</td>
                          <td>{row.name}</td>
                          <td>{row.start_time}</td>
                          <td>{row.end_time}</td>
                          <td>
                            {row.is_active  ? (
                              <span className="badge-item badge-status-success">
                                {/* {row.status} */}
                              Active
                              </span>
                            ) : (
                              <span className="badge-item badge-status">
                                {/* {row.is_active} */}
                                Inactive
                              </span>
                            )}
                          </td>
                          <td className="action-btns">
                            <Edit state={row} />
                            {/* <Typography className="delete-btn" onClick={(e) => this.handlDeleteById(row.id)} ><i className="fas fa-trash-alt" /></Typography> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
