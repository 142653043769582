import React, { Component } from "react";
import { Button } from "@material-ui/core";
import AutoSelect from "../../../../common/autoselect";
import { GetDeliverySlots } from "../../../../services";
import swal from "sweetalert";


export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      start_time: "",
      end_time: "",
      is_active: 1,
    };
  }
  handleBack() {
    this.props.history.goBack();
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  // async componentDidMount() {
  //   this.GetDeliverySlots();
  // }

  // async GetDeliverySlots() {
  //   let list = await GetDeliverySlots.addDeliverySlots();
  //   this.setState({ getList: list.data });
  // }

  formatTimeTo12Hour(time24hr) {
    const [hours, minutes] = time24hr.split(":");
    let period = "AM";
    let formattedHours = parseInt(hours, 10);

    if (formattedHours >= 12) {
      formattedHours -= 12;
      period = "PM";
    }

    formattedHours = formattedHours || 12; // Handle midnight (00:00)

    return `${formattedHours}:${minutes} ${period}`;
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, start_time, end_time, is_active } = this.state;

    if (name && start_time && end_time) {
      const formattedStartTime = this.formatTimeTo12Hour(start_time);
      const formattedEndTime = this.formatTimeTo12Hour(end_time);
      let data = {
        name: name,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        is_active: is_active
      };
      swal({
        title: "Are you sure?",
        text: "You want to Add New Delivery Slot",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (success) => {
        if (success) {
          let list = await GetDeliverySlots.addDeliverySlots(data);
          if (list) {
            this.setState({ getList: list.data });
            window.location.href = "/admin/deliveryslots/list";
          }
        }
      });
    } else {
      swal("Error", "Please select a all the fields before adding.", "error");
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">Delivery Slots</h2>
          </div>
          <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
            <Button variant="contained" onClick={(e) => this.handleBack()}>
              <i class="fas fa-arrow-left" /> Back
            </Button>
          </div>
        </div>
        <div align="center">
          <div className="col-lg-4 col-md-6">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>Add New Delivery Slot</h4>
              </div>
              <div className="card-body-table">
                <div className="news-content-right pd-20">
                  <div align="center" className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Slot Name"
                      name="name"
                      value={this.state.name}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    {/* <label className="form-label">Pincode*</label> */}
                    <input
                      type="time"
                      ampm="true"
                      className="custom-select"
                      placeholder="Start Time"
                      name="start_time"
                      value={this.state.start_time}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    {/* <label className="form-label">Delivery Fees*</label> */}
                    <input
                      type="time"
                      ampm="true"
                      className="custom-select"
                      placeholder="End Time"
                      name="end_time"
                      value={this.state.end_time}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>

                  <div className="form-group">
                    {/* <label className="form-label">Status*</label> */}
                    <select
                      id="is_active"
                      name="is_active"
                      className="form-control"
                      value={this.state.is_active}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </select>
                  </div>
                  <button
                    className="save-btn hover-btn"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Add New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
