import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import { getCookie } from "../../../function";

export default class Sidebar extends Component {
  render() {
    let role = getCookie("role");
    return (
      <div id="layoutSidenav_nav">
        <nav
          className="sb-sidenav accordion sb-sidenav-dark "
          id="sidenavAccordion"
        >
          <div className="sb-sidenav-menu">
            <div className="nav">
              <a className="nav-link" href="/">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-tachometer-alt" />
                </div>
                <u>
                  <b>Dashboard</b>
                </u>
              </a>

              {/* <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseShops" aria-expanded="false" aria-controls="collapseShops">
                                <div className="sb-nav-link-icon"><i className="fas fa-store" /></div>
                                Vendors
                            <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                            </a> */}
              {/* <div className="collapse" id="collapseShops" aria-labelledby="headingTwo" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link sub_nav_link" href="/admin/shop/list">list</a>
                                    <a className="nav-link sub_nav_link" href="/admin/shop/create">create</a>
                                    <a className="nav-link sub_nav_link" href="/admin/vendor/product/list">Product</a>
                                </nav>
                            </div> */}
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseProducts"
                aria-expanded="false"
                aria-controls="collapseProducts"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-box" />
                </div>
                Products
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down" />
                </div>
              </a>
              <div
                className="collapse"
                id="collapseProducts"
                aria-labelledby="headingTwo"
                data-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/product/list"
                  >
                    All Products
                  </a>
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/product/create"
                  >
                    Add Product
                  </a>
                  {/* <a className="nav-link sub_nav_link" href="/admin/product/more-photo">Add More Image</a> */}
                </nav>
              </div>
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseCategories"
                aria-expanded="false"
                aria-controls="collapseCategories"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-list" />
                </div>
                Categories
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down" />
                </div>
              </a>
              <div
                className="collapse"
                id="collapseCategories"
                aria-labelledby="headingTwo"
                data-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/category/list"
                  >
                    All Categories
                  </a>
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/category/create"
                  >
                    Add Category
                  </a>
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/category/sub-create"
                  >
                    Add Sub-Category
                  </a>
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/category/sub-child-create"
                  >
                    Add Child-Category
                  </a>
                </nav>
              </div>
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseLocations"
                aria-expanded="false"
                aria-controls="collapseLocations"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-map-marker-alt" />
                </div>
                Locations
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down" />
                </div>
              </a>
              <div
                className="collapse"
                id="collapseLocations"
                aria-labelledby="headingTwo"
                data-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/location/list"
                  >
                    All Locations
                  </a>
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/location/create"
                  >
                    Add Location
                  </a>
                </nav>
              </div>
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseAreas"
                aria-expanded="false"
                aria-controls="collapseAreas"
              >
                <div className="sb-nav-link-icon">
                  <i className="fas fa-map-marked-alt" />
                </div>
                Areas
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down" />
                </div>
              </a>
              <div
                className="collapse"
                id="collapseAreas"
                aria-labelledby="headingTwo"
                data-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <a className="nav-link sub_nav_link" href="/admin/area/list">
                    All Areas
                  </a>
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/area/create"
                  >
                    Add Area
                  </a>
                </nav>
              </div>

              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseDeliverySlots"
                aria-expanded="false"
                aria-controls="collapseDeliverySlots"
              >
                <div className="sb-nav-link-icon">
                  <i className="far fa-calendar-alt" />
                </div>
                Delivery Slots
                <div className="sb-sidenav-collapse-arrow">
                  <i className="fas fa-angle-down" />
                </div>
              </a>
              <div
                className="collapse"
                id="collapseDeliverySlots"
                aria-labelledby="headingTwo"
                data-parent="#sidenavAccordion"
              >
                <nav className="sb-sidenav-menu-nested nav">
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/deliveryslots/list"
                  >
                    View Slots
                  </a>
                  <a
                    className="nav-link sub_nav_link"
                    href="/admin/deliveryslots/create"
                  >
                    Add Slot
                  </a>
                </nav>
              </div>

              <a className="nav-link" href="/admin/user/create">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-user" />
                </div>
                Add New User
              </a>
              <a className="nav-link" href="/admin/user/list">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-address-card" />
                </div>
                Roles Management
              </a>
              {/* <a className={role === "admin"?"nav-link":"d-none"} href="/admin/user/list">
                                <div className="sb-nav-link-icon"><i className="fas fa-users" /></div>
                                Roles Management
                            </a> */}
              <a className="nav-link" href="/admin/customer/list">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-users" />
                </div>
                Customers
              </a>
              {/* <a className="nav-link" href="/admin/payment/list">
                                <div className="sb-nav-link-icon"><i className="far fa-money-bill-alt" /></div>
                                Payment
                            </a> */}

              {/* <a className="nav-link" href=" ">
                                <div className="sb-nav-link-icon"><i className="fas fa-users" /></div>
                                Add New Users
                            </a> */}

              {/* <a className="nav-link" href=" ">
                                <div className="sb-nav-link-icon"><i className="fas fa-users" /></div>
                                top products
                            </a> */}
              {/* <a className="nav-link" href=" ">
                                <div className="sb-nav-link-icon"><i className="fas fa-users" /></div>
                                Best offers
                            </a> */}
              {/* <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon"><i className="fas fa-newspaper" /></div>
                                Posts
                                 <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                            </a>
                            <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link sub_nav_link" href="posts.html">All Posts</a>
                                    <a className="nav-link sub_nav_link" href="add_post.html">Add New</a>
                                    <a className="nav-link sub_nav_link" href="post_categories.html">Categories</a>
                                    <a className="nav-link sub_nav_link" href="post_tags.html">Tags</a>
                                </nav>
                            </div>
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAreas" aria-expanded="false" aria-controls="collapseAreas">
                                <div className="sb-nav-link-icon"><i className="fas fa-map-marked-alt" /></div>
                                Areas
          <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                            </a>
                            <div className="collapse" id="collapseAreas" aria-labelledby="headingTwo" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link sub_nav_link" href="areas.html">All Areas</a>
                                    <a className="nav-link sub_nav_link" href="add_area.html">Add Area</a>
                                </nav>
                            </div>
                            
                            
                            <a className="nav-link" href="orders.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-cart-arrow-down" /></div>
                                Orders
        </a>
                            <a className="nav-link" href="customers.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-users" /></div>
                                Customers
        </a>
                            <a className="nav-link" href="offers.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-gift" /></div>
                                Offers
        </a>
                            <a className="nav-link" href="pages.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-book-open" /></div>
                                Pages
        </a>
                            <a className="nav-link" href="menu.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-layer-group" /></div>
                                Menu
        </a>
                            <a className="nav-link" href="updater.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-cloud-upload-alt" /></div>
                                Updater
        </a>
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                                <div className="sb-nav-link-icon"><i className="fas fa-cog" /></div>
                                Setting
          <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
                            </a>
                            <div className="collapse" id="collapseSettings" aria-labelledby="headingTwo" data-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <a className="nav-link sub_nav_link" href="general_setting.html">General Settings</a>
                                    <a className="nav-link sub_nav_link" href="payment_setting.html">Payment Settings</a>
                                    <a className="nav-link sub_nav_link" href="email_setting.html">Email Settings</a>
                                </nav>
                            </div>
                            <a className="nav-link" href="reports.html">
                                <div className="sb-nav-link-icon"><i className="fas fa-chart-bar" /></div>
                                Reports
        </a> */}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { getCookie } from '../../../function';

// export default class Sidebar extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             activeLink: ''
//         };
//     }

//     handleLinkClick = (link) => {
//         this.setState({ activeLink: link });
//     };

//     render() {
//         let role = getCookie("role");
//         const { activeLink } = this.state;

//         return (
//             <div id="layoutSidenav_nav">
//                 <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
//                     <div className="sb-sidenav-menu">
//                         <div className="nav">
//                             <Link
//                                 className={`nav-link ${activeLink === 'dashboard' ? 'active' : ''}`}
//                                 to="/"
//                                 onClick={() => this.handleLinkClick('dashboard')}
//                             >
//                                 <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt" /></div>
//                                 <u><h5><b>Dashboard</b></h5></u>
//                             </Link>

//                             <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseProducts" aria-expanded="false" aria-controls="collapseProducts">
//                                 <div className="sb-nav-link-icon"><i className="fas fa-box" /></div>
//                                 Products
//                                 <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
//                             </a>
//                             <div className="collapse" id="collapseProducts" aria-labelledby="headingTwo" data-parent="#sidenavAccordion">
//                                 <nav className="sb-sidenav-menu-nested nav">
//                                     <Link
//                                         className={`nav-link sub_nav_link ${activeLink === 'allProducts' ? 'active' : ''}`}
//                                         to="/admin/product/list"
//                                         onClick={() => this.handleLinkClick('allProducts')}
//                                     >
//                                         All Products
//                                     </Link>
//                                     <Link
//                                         className={`nav-link sub_nav_link ${activeLink === 'addProduct' ? 'active' : ''}`}
//                                         to="/admin/product/create"
//                                         onClick={() => this.handleLinkClick('addProduct')}
//                                     >
//                                         Add Product
//                                     </Link>
//                                     <Link
//                                         className={`nav-link sub_nav_link ${activeLink === 'addMoreImage' ? 'active' : ''}`}
//                                         to="/admin/product/more-photo"
//                                         onClick={() => this.handleLinkClick('addMoreImage')}
//                                     >
//                                         Add More Image
//                                     </Link>
//                                 </nav>
//                             </div>

//                             <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCategories" aria-expanded="false" aria-controls="collapseCategories">
//                                 <div className="sb-nav-link-icon"><i className="fas fa-list" /></div>
//                                 Categories
//                                 <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
//                             </a>
//                             <div className="collapse" id="collapseCategories" aria-labelledby="headingTwo" data-parent="#sidenavAccordion">
//                                 <nav className="sb-sidenav-menu-nested nav">
//                                     <Link
//                                         className={`nav-link sub_nav_link ${activeLink === 'allCategories' ? 'active' : ''}`}
//                                         to="/admin/category/list"
//                                         onClick={() => this.handleLinkClick('allCategories')}
//                                     >
//                                         All Categories
//                                     </Link>
//                                     <Link
//                                         className={`nav-link sub_nav_link ${activeLink === 'addCategory' ? 'active' : ''}`}
//                                         to="/admin/category/create"
//                                         onClick={() => this.handleLinkClick('addCategory')}
//                                     >
//                                         Add Category
//                                     </Link>
//                                     <Link
//                                         className={`nav-link sub_nav_link ${activeLink === 'addSubCategory' ? 'active' : ''}`}
//                                         to="/admin/category/sub-create"
//                                         onClick={() => this.handleLinkClick('addSubCategory')}
//                                     >
//                                         Add Sub-Category
//                                     </Link>
//                                     <Link
//                                         className={`nav-link sub_nav_link ${activeLink === 'addChildCategory' ? 'active' : ''}`}
//                                         to="/admin/category/sub-child-create"
//                                         onClick={() => this.handleLinkClick('addChildCategory')}
//                                     >
//                                         Add Child-Category
//                                     </Link>
//                                 </nav>
//                             </div>

//                             <Link
//                                 className={`nav-link ${activeLink === 'addNewUser' ? 'active' : ''}`}
//                                 to="/admin/user/create"
//                                 onClick={() => this.handleLinkClick('addNewUser')}
//                             >
//                                 <div className="sb-nav-link-icon"><i className="fas fa-user" /></div>
//                                 Add New User
//                             </Link>
//                             <Link
//                                 className={`nav-link ${activeLink === 'rolesManagement' ? 'active' : ''}`}
//                                 to="/admin/user/list"
//                                 onClick={() => this.handleLinkClick('rolesManagement')}
//                             >
//                                 <div className="sb-nav-link-icon"><i className="fas fa-address-card" /></div>
//                                 Roles Management
//                             </Link>
//                             <Link
//                                 className={`nav-link ${activeLink === 'customers' ? 'active' : ''}`}
//                                 to="/admin/customer/list"
//                                 onClick={() => this.handleLinkClick('customers')}
//                             >
//                                 <div className="sb-nav-link-icon"><i className="fas fa-users" /></div>
//                                 Customers
//                             </Link>
//                             <Link
//                                 className={`nav-link ${activeLink === 'payment' ? 'active' : ''}`}
//                                 to="/admin/payment/list"
//                                 onClick={() => this.handleLinkClick('payment')}
//                             >
//                                 <div className="sb-nav-link-icon"><i className="far fa-money-bill-alt" /></div>
//                                 Payment
//                             </Link>
//                         </div>
//                     </div>
//                 </nav>
//             </div>
//         );
//     }
// }
