import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { GetPaymentDetails } from "../../../../services";
import { NotificationManager } from "react-notifications";
import Moment from "react-moment";
import Loader from "../../../../loader";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
export default class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getList: [],
      isLoaded: false,
      perPage: 20,
      offset: 0,
      orgtableData: [],
      currentPage: 0,
    };
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      getList: slice,
    });
  }
  handleBack() {
    this.props.history.goBack();
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });
    this.getCustomer();
  }
  //   async getCustomer() {
  //     let list = await GetPaymentDetails.getAllPaymentList();
  //     if (list) {
  //       this.setState({ getList: list.data, isLoaded: false });
  //     }
  //   }
  async getCustomer() {
    let list = await GetPaymentDetails.getAllPaymentList();
    if (list) {
      this.setState({
        getList: list.data,
        isLoaded: false,
        orgtableData: list.data,
        pageCount: Math.ceil(list.data.length / this.state.perPage),
      });
    }
  }

  async handlDeleteById(id) {
    swal({
      title: "Are you sure?",
      text: "You want to delete User from the List",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        let value = await GetPaymentDetails.getDeleteUserList(id);
        if (value) {
          NotificationManager.success(value.msg, "Status");
          setTimeout(async function () {
            window.location.reload();
          }, 1000);
        }
      }
    });
  }
  handlEditRow(row) {
    this.props.history.push({
      pathname: `/admin/user/edit/${row.id}`,
      state: row,
    });
  }
  handleAddNewUser() {
    this.props.history.push({ pathname: `/admin/user/create` });
  }
  render() {
    const { getList, isLoaded } = this.state;
    const sortedList = getList.slice().sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">Payment List</h2>
          </div>
          <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
            <Button variant="contained" onClick={(e) => this.handleBack()}>
              <i class="fas fa-arrow-left" /> Back
            </Button>
          </div>
        </div>
        {/* <ol className="breadcrumb mb-30">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active">payment</li>
                </ol> */}
        <div className="row justify-content-between">
          <div className="col-lg-3 col-md-4">
            <div className="bulk-section mt-30">
              {/* <div className="input-group">
                                <select id="action" name="action" className="form-control">
                                    <option selected>Bulk Actions</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Inactive</option>
                                    <option value={3}>Delete</option>
                                </select>
                                <div className="input-group-append">
                                    <button className="status-btn hover-btn" type="submit">Apply</button>
                                </div>
                            </div> */}
            </div>
          </div>
          {/* <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                        <Button variant="contained" className="status-btn hover-btn" onClick={(e) => this.handleAddNewUser()}>Add New User</Button>
                    </div> */}
          <div className="col-lg-12 col-md-12">
            {isLoaded ? <Loader /> : ""}
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        {/* <th style={{ width: 60 }}>
                          <input type="checkbox" className="check-all" />
                        </th> */}
                        <th style={{ width: 60 }}>Sl No.</th>
                        <th>OrderId</th>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Transaction Amount</th>
                        <th>Payment Type</th>
                        <th>Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {getList.map((row, index) => ( */}
                      {sortedList.map((row, index) => (
                        <tr key={index}>
                          {/* <td>
                            <input
                              type="checkbox"
                              className="check-item"
                              name="ids[]"
                              defaultValue={7}
                            />
                          </td> */}
                          {/* <td>{++index}</td> */}
                          <td>{index + 1}</td>
                          <td>{row.orderCreationId}</td>
                          <td>
                            <span className="delivery-time">
                              <Moment format="MMMM Do YYYY">
                                {row.createdAt}
                              </Moment>
                            </span>
                            <span className="delivery-time">
                              <Moment format=" h:mm:ss a">
                                {row.createdAt}
                              </Moment>
                            </span>
                          </td>
                          <td>
                            {row.customer
                              ? row.customer.firstName +
                                " " +
                                row.customer.lastName
                              : ""}
                          </td>
                          <td>{row.amount}</td>
                          <td>{row.method}</td>
                          <td>
                            {row.status === "captured" ? (
                              <span className="text-success">success</span>
                            ) : (
                              <span className="text-danger">failed</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={20}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
