import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import MainCategorylist from "../../../../../common/category/main-category";
import SubCategorylist from "../../../../../common/category/sub-category";
import { GetCategoryDetails } from "../../../../../services";
import swal from "sweetalert";

export default class SubChildCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      error: "",
      getdata: [],
      getList: [],
      selectedCategory: "",
      selectedSubCategory: "",
    };
  }

  handleBack() {
    this.props.history.goBack();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value, error: "" });
  }
  // handleChange = (e) => {
  //     const value = e.target.value;
  //     this.setState({ name: value, error: '' });
  // };
  handleKeyPress = (e) => {
    const regex = /^[a-zA-Z0-9\s,-]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
      this.setState({
        error: "Special characters and numbers are not allowed.",
      });
    }
  };
  handleSubCategory = (value) => {
    this.setState({ selectedSubCategory: value });
  };
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  handleCategory = async (value) => {
    this.setState({ selectedCategory: value });
    let categoryId = value;
    let list = await GetCategoryDetails.getSelectSubCategory(categoryId);
    this.setState({ getList: list.data });
  };
  async getChildCategory() {
    let list = await GetCategoryDetails.getChildCategoryList();
    this.setState({ getdata: list.data });
  }
  async componentDidMount() {
    this.getChildCategory();
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    let data = {
      name: this.state.name,
      categoryId: this.state.selectedCategory,
      subcategoryId: this.state.selectedSubCategory,
    };
    swal({
      title: "Are you sure?",
      text: "You want to Add New Child-Category",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        let list = await GetCategoryDetails.createChildCategory(data);
        if (list) {
          this.getChildCategory();
        }
      }
    });
  };
  async handlDeleteById(id) {
    swal({
      title: "Are you sure?",
      text: "You want to delete Category from the List",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        let value = await GetCategoryDetails.getChildDeleteById(id);
        if (value) {
          this.getChildCategory();
        }
      }
    });
  }
  render() {
    const { getList, getdata } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">Add Child Category</h2>
          </div>
          <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
            <Button variant="contained" onClick={(e) => this.handleBack()}>
              <i class="fas fa-arrow-left" /> Back
            </Button>
          </div>
        </div>
        {/* <ol className="breadcrumb mb-30">
                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                    <li className="breadcrumb-item active">Child Category</li>
                </ol> */}
        <div className="row">
          <div className="col-lg-4 col-md-5">
            <div className="card card-static-2 mb-30">
              {/* <div className="card-title-2">
                                <h4>Add Child Category</h4>
                            </div> */}
              <div className="card-body-table">
                <div className="news-content-right pd-20">
                  <div className="form-group mb-0">
                    <label className="form-label">Main Category*</label>
                    <MainCategorylist onSelectCategory={this.handleCategory} />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Sub Category*</label>
                    <SubCategorylist
                      state={getList}
                      onSelectSubCategory={this.handleSubCategory}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Child Catagory Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Child Catagory Name"
                      name="name"
                      value={this.state.name}
                      onChange={(e) => this.handleChange(e)}
                      onKeyPress={this.handleKeyPress}
                    />
                    {this.state.error && (
                      <p className="text-danger">*{this.state.error}</p>
                    )}
                  </div>

                  <button
                    className="save-btn hover-btn"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Add New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-7">
            <div className="all-cate-tags">
              <div className="row justify-content-between">
                <div className="col-lg-12 col-md-12">
                  <div className="card card-static-2 mb-30">
                    <div className="card-title-2">
                      <h4>All Child Categories</h4>
                    </div>
                    <div className="card-body-table">
                      <div className="table-responsive">
                        <table className="table ucp-table table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: 60 }}>
                                {/* <input type="checkbox" className="check-all" /> */}
                                Sl No.
                              </th>
                              <th scope="col">Category </th>
                              <th scope="col">Sub Category</th>
                              <th scope="col">Child Category</th>
                              <th scope="col">Date</th>
                              {/* <th scope="col">Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {getdata.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <input
                                    type="checkbox"
                                    className="check-item"
                                    name="ids[]"
                                    defaultValue={5}
                                  /> */}
                                  {index+1}
                                </td>
                                <td>
                                  {row.SubCategory
                                    ? row.SubCategory.category.name
                                    : ""}
                                </td>
                                <td>
                                  {row.SubCategory
                                    ? row.SubCategory.sub_name
                                    : ""}
                                </td>
                                <td>{row.name}</td>
                                <td>
                                  <span className="delivery-time">
                                    {this.formatDate(row.createdAt)}
                                  </span>
                                </td>
                                {/* <td className="action-btns">
                                                                        <ChildEdit state={row} />
                                                                        <Typography className="delete-btn" onClick={(e) => this.handlDeleteById(row.id)} ><i className="fas fa-trash-alt" /></Typography>
                                                                    </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }
}
