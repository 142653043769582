import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { GetOrderDetails } from "../../../../services";
import { NotificationManager } from "react-notifications";
import Moment from "react-moment";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state.row.id,
      status: this.props.location.state.row.status,
      deliverydate: "",
    };
  }
  handleBack() {
    this.props.history.goBack();
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleUpdateStatus = async (event) => {
    let data = {
      status: this.state.status,
      id: this.state.id,
      deliverydate: new Date(this.state.deliverydate),
    };
    if (data) {
      let update = await GetOrderDetails.getOrderStatusUpdate(data);
      if (update) {
        NotificationManager.success(update.msg, "Status");
        setTimeout(async function () {
          window.location.href = "/admin";
        }, 1000);
      } else {
        NotificationManager.error("Check Status", "Status");
      }
    }
    // console.log("Edit -> handleUpdateStatus -> data", data);
  };
  render() {
    let self = this.props.location.state;
    // let deliveryslot = JSON.parse(self.row.deliveryslot);

    // console.log("Edit -> render -> self", self);
    return (
      <div>
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Orders</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button variant="contained" onClick={(e) => this.handleBack()}>
                  <i class="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            {/* <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Orders</a>
              </li>
              <li className="breadcrumb-item active">Order Edit</li>
            </ol> */}
            <div className="row">
              {self?.row ? (
                <div className="col-xl-12 col-md-12">
                  <h2 className="title1458">Invoice</h2>
                  <div className="card card-static-2 mb-30">
                    <div className="card-title-2">
                      {/* <h2 className="title1458">Invoice</h2> */}
                      <span>
                        <div align="left" className="ordr-date">
                          <u>
                            <b>Order Details :</b>
                          </u>
                          <br />
                          <b>Order_Id:</b> {self.row.number}
                          <br />
                          <b>Order Date:</b>{" "}
                          <Moment format="MMMM Do YYYY">
                            {self.row.createdAt}
                          </Moment>;&nbsp;&nbsp;
                          <Moment format="H:MM:SS A">
                            {self.row.createdAt}
                          </Moment>
                          ,<br />
                        </div>
                      </span>
                      <span className="order-id">
                        <div align="left" className="ordr-date">
                          {self.row?.Addresses?.map((data, index) => (
                            <div className="ordr-date right-text" key={index}>
                              <u>
                                <b>Billing Address :</b>
                              </u>
                              <br />
                              {data.fullname},<br />
                              {data.phone},<br />
                              {data.area},&nbsp;
                              {data.city},<br />
                              {data.discrict},&nbsp;
                              {data.states},&nbsp;
                              {data.pincode},<br />
                              <b>
                                <u>Landmark:-</u>
                              </b>
                              &nbsp;{data.shipping}
                            </div>
                          ))}
                        </div>
                      </span>
                    </div>
                    <div className="invoice-content">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="ordr-date">
                            {self.row?.Addresses?.map((data, index) => (
                              <div key={index}>
                                <br />
                                <u>
                                  <b>Customer Details :</b>
                                </u>
                                {/* <br /> */}
                                {/* <b>Customer_Id: </b>
                                {data.custId} */}
                                <br />
                                <b>Name: </b>
                                {data.fullname},<br />
                                <b>Mobile: </b>
                                {data.phone}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          {self.row?.Addresses?.map((data, index) => (
                            <div className="ordr-date right-text" key={index}>
                              <u>
                                <b>Shipping Address :</b>
                              </u>
                              <br />
                              {data.fullname},<br />
                              {data.phone},<br />
                              {data.area},&nbsp;
                              {data.city}, <br />
                              {data.discrict},&nbsp;
                              {data.states},&nbsp;
                              {data.pincode}, <br />
                              <b>
                                <u>Landmark:-</u>
                              </b>
                              &nbsp;{data.shipping}
                            </div>
                          ))}
                        </div>
                        <div className="col-lg-12">
                          <div className="card card-static-2 mb-30 mt-30">
                            <div className="card-title-2">
                              <h4>Recent Orders</h4>
                            </div>
                            <div className="card-body-table">
                              <div className="table-responsive">
                                <table className="table ucp-table table-hover">
                                  <thead>
                                    <tr>
                                      <th style={{ width: 130 }}>Sl No.</th>
                                      <th>Image</th>
                                      <th>Item</th>
                                      <th
                                        style={{ width: 150 }}
                                        className="text-center"
                                      >
                                        Price
                                      </th>
                                      <th
                                        style={{ width: 150 }}
                                        className="text-center"
                                      >
                                        Qty
                                      </th>
                                      <th
                                        style={{ width: 100 }}
                                        className="text-center"
                                      >
                                        Total
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {self.row?.Addresses?.map((prop) => {
                                      return prop?.Carts?.map((p, index) => (
                                        <tr key={index}>
                                          {/* <td>{p.id}</td> */}
                                          <td>{1 + index++}.</td>
                                          <td>
                                            <img
                                              src={p.photo}
                                              alt="cartimage"
                                              style={{ height: "50px" }}
                                            />
                                          </td>
                                          <td>{p.name}</td>
                                          <td className="text-center">
                                            &#8377;{p.price}
                                          </td>
                                          <td className="text-center">
                                            {p.qty}
                                          </td>
                                          <td className="text-center">
                                            &#8377;{p.total}
                                          </td>
                                        </tr>
                                      ));
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7" />
                        <div className="col-lg-5">
                          <div className="order-total-dt">
                            <div className="order-total-left-text">
                              Sub Total
                            </div>
                            <div className="order-total-right-text">
                              &#8377;{self.row.subtotal}
                            </div>
                          </div>
                          <div className="order-total-dt">
                            <div className="order-total-left-text">
                              Delivery Fees
                            </div>
                            <div className="order-total-right-text">
                              &#8377;{self.row.deliveryfees}
                            </div>
                          </div>
                          <div className="order-total-dt">
                            <div className="order-total-left-text fsz-18">
                              Total Amount
                            </div>
                            <div className="order-total-right-text fsz-18">
                              &#8377;{self.row.grandtotal}
                            </div>
                          </div>
                          <div className="order-total-dt">
                            <div className="order-total-left-text">
                              Payment Method
                            </div>
                            <div className="order-total-right-text">
                              &#8377;{self.row.paymentmethod}
                            </div>
                          </div>
                          <div className="order-total-dt">
                            <div className="order-total-left-text">
                              Delivery SLot
                            </div>
                            {/* <div className="order-total-right-text" style={{color:"red "}}>
                              {deliveryslot.name}&nbsp;(
                              {deliveryslot.start_time}-{deliveryslot.end_time})
                            </div> */}
                            <div
                              className="order-total-right-text"
                              style={{ color: "red " }}
                            >
                              {self.row.deliveryslot}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7" />
                        <div className="col-lg-5">
                          <div className="select-status">
                            <label
                              htmlFor="status"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              Delivery Date:
                              {self.row.deliverydate ? (
                                <span style={{ color: "red" }}>
                                  <Moment format="MMMM Do YYYY">
                                    {self.row.deliverydate}
                                  </Moment>
                                  {/* {self.row.deliverydate.substring(0, 10)} */}
                                </span>
                              ) : (
                                <></>
                              )}
                            </label>

                            <div className="input-group">
                              {self.row.status === "processing" ? (
                                <input
                                  className="custom-select"
                                  type="date"
                                  name="deliverydate"
                                  value={this.state.deliverydate}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              ) : self.row.status === "shipping" ? (
                                <input
                                  className="custom-select"
                                  type="date"
                                  name="deliverydate"
                                  value={this.state.deliverydate}
                                  onChange={(e) => this.handleChange(e)}
                                />
                              ) : self.row.status === "delieverd" ? (
                                <></>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="select-status">
                            <label htmlFor="status">Status*</label>
                            <div className="input-group">
                              {self.row.status === "processing" ? (
                                <select
                                  id="status"
                                  name="status"
                                  className="custom-select"
                                  value={this.state.status}
                                  onChange={(e) => this.handleChange(e)}
                                >
                                  <option value="processing" disabled>
                                    Processing
                                  </option>
                                  <option value="shipping">Shipping</option>
                                  <option value="delieverd">Delivered</option>
                                  <option value="cancel">Cancel</option>
                                </select>
                              ) : self.row.status === "shipping" ? (
                                <select
                                  id="status"
                                  name="status"
                                  className="custom-select"
                                  value={this.state.status}
                                  onChange={(e) => this.handleChange(e)}
                                >
                                  <option value="shipping" disabled>
                                    Shipping
                                  </option>
                                  <option value="delieverd">Delivered</option>
                                  <option value="cancel">Cancel</option>
                                </select>
                              ) : self.row.status === "delieverd" ? (
                                <select
                                  id="status"
                                  name="status"
                                  className="custom-select"
                                  value={this.state.status}
                                  onChange={(e) => this.handleChange(e)}
                                  disabled
                                >
                                  <option value="delieverd">Delivered</option>
                                </select>
                              ) : (
                                <select
                                  id="status"
                                  name="status"
                                  className="custom-select"
                                  value={this.state.status}
                                  onChange={(e) => this.handleChange(e)}
                                  disabled
                                >
                                  <option value="cancel">Cancel</option>
                                </select>
                              )}
                              <div className="input-group-append">
                                <button
                                  className="status-btn hover-btn"
                                  type="submit"
                                  onClick={this.handleUpdateStatus}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                "Loading"
              )}
            </div>
          </div>
        </main>
      </div>
    );
  }
}
