import React, { Component } from "react";
import { Modal } from "@material-ui/core";
import { GetCategoryDetails } from "../../../../../services";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    const { name, slug, error } = this.props.state;
    this.state = {
      name: name,
      slug: slug,
      error: error,
    };
  }
  sanitizeInput(input) {
    return input.replace(/[^a-zA-Z0-9\s,-]/g, "");
  }
  handleChange(e) {
    const name = e.target.name;
    const value = this.sanitizeInput(e.target.value); // Sanitize the input
    this.setState({ [name]: value, error: "" });
  }
  handleKeyPress = (e) => {
    const regex = /^[a-zA-Z0-9\s,-]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
      this.setState({
        error: "Special characters are not allowed in this field.",
      });
    }
  };
  // handlePaste(e) {
  //   const clipboardData = e.clipboardData || window.clipboardData;
  //   const pastedText = clipboardData.getData("text/plain");
  //   const sanitizedText = this.sanitizeInput(pastedText);
  //   document.execCommand("insertText", false, sanitizedText);
  //   e.preventDefault();
  //   this.setState({
  //     error: "Special characters and numbers are not allowed.",
  //   });
  // }

  handlePaste(e) {
    e.preventDefault();
    this.setState({
      error: "Pasting is not allowed in this field.",
    });
  }
  handleOpen() {
    this.setState({ open: !this.state.open, loading: true });
  }

  handleClose() {
    this.setState({ open: !this.state.open });
  }
  async handleSubmit(e) {
    let data = {
      id: this.props.state.id,
      name: this.state.name,
      slug: this.state.slug,
    };
    let list = await GetCategoryDetails.getUpdateCategoryList(data);
    if (list) {
      // window.location.href = "/"
      window.location.reload();
    }
  }
  render() {
    return (
      <div>
        <a className="edit-btn" onClick={(e) => this.handleOpen()}>
          <i className="fas fa-edit" />
        </a>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Catagory
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.handleClose()}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label">Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={(e) => this.handleChange(e)}
                    onKeyPress={this.handleKeyPress}
                    onPaste={(e) => this.handlePaste(e)}
                  />
                  {this.state.error && (
                    <p className="text-danger">*{this.state.error}</p>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Slug*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Category slug"
                    name="slug"
                    // value={this.state.name.toLowerCase().replace(/ |,/g, "-")}
                    value={this.state.slug}
                    disabled
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.handleClose()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleSubmit()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
