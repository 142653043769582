import React from 'react';

const Footer = () => {
    return (
        <div style={styles.footer}>

<div>
            <div className="row no-gutters">
              <div className="col-lg-6 col-sm-6">
                <p className="mt-1 mb-0">
                  <strong className="text-dark">&copy; {new Date().getFullYear()} Pickpack</strong>. All Rights
                  Reserved
                  <br />
                  <small className="mt-0 mb-0">
                    Made by{" "}
                    <a href="https://www.hungrytop.com/" target="blank">
                      Hungrytop Technologies Limited
                    </a>
                  </small>
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 text-right">
                {" "}
                {/* Added "text-right" class */}
                <a className="text-dark"
                  href="https://www.hungrytop.com/privacy-policy"
                  target="blank"
                >
                  Privacy Policy
                </a>
                <br />
                <a className="text-dark"
                  href="https://www.hungrytop.com/terms-and-conditions"
                  target="blank"
                >
                  Terms & Conditions
                </a>
              </div>
            </div>
          </div>
        </div>
    );
}

export default Footer;

const styles = {
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#D3D3D3',
        color: '#000000',
        padding: '0.5px 250px',
        textAlign: 'left',
        fontSize: '15px',
    },
    footerText: {
        margin: 0,
    },
};

